<template>
  <base-section
    id="bathhouses"
    class="grey lighten-4"
    space="36"
  >
    <v-container fluid>
      <base-section-heading
        class="pt-0"
        :title="$i18n.t('sanitised')"
      />
      <v-row>
        <v-col class="d-flex justify-center">
          <v-carousel
            continuous
            cycle
            :interval="300000"
            hide-delimiters
            :height="$vuetify.breakpoint.smAndDown ? 100 : 200"
            style="max-width: 800px;"
            hide-delimiter-background
          >
            <template v-slot:prev="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="grey grey--darken-4"
                large
              >
                <v-icon large>fas fa-chevron-left</v-icon>
              </v-btn>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="grey grey--darken-4"
                large
              >
                <v-icon large>fas fa-chevron-right</v-icon>
              </v-btn>
            </template>
            <v-carousel-item
              v-for="n in 7"
              :key="n"
            >
              <div
                class="d-flex justify-center align-center"
                :style="{ height: $vuetify.breakpoint.smAndDown ? 100 : 200 }"
              >
                <v-img
                  :src="require(`@/assets/imgs/bathhouses/logo-${n}.png`)"
                  :max-height="$vuetify.breakpoint.smAndDown ? 100 : 200"
                  :max-width="$vuetify.breakpoint.smAndDown ? 250 : 600"
                  contain
                />
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>

  export default {
    name: 'Bathhouses',
  }
</script>
<style>
#bathhouses .container .v-window__prev, #bathhouses .container .v-window__next {
  background: none !important;
}
</style>
<i18n>
{
	"en": {
		"sanitised": "Sanitised bathhouses"
	},
	"it": {
		"sanitised": "Hanno già aderito"
	}
}
</i18n>
